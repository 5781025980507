import $ from 'utils/jquery'
import 'utils/lazyframe'

$(document).on('turbolinks:load', function () {
  const ratingButton = document.querySelector('#rating-card-link');
  const ratingTab = document.querySelector('#ratings-link');

  $(window).scroll(function(){
    if($(this).scrollTop() > 60)
      $('#social-bar').addClass('position-fixed');
    else
      $('#social-bar').removeClass('position-fixed');
  });
  if (ratingButton) {
    ratingButton.addEventListener('click', ()=>{
      // console.log("HALLO");
      $('#ratings').tab('show');
      ratingTab.click();
      $('html, body').animate({
        scrollTop: $("#ratings").offset().top
      }, 500);
    })
  }
})




