// src/application.js
import { Application } from "@hotwired/stimulus"


const Stimulus = Application.start()

import AutosubmitOnchangeController from "./autosubmit_onchange_controller"
import RuleProgressController from "./rule_progress_controller"
import RuleProgressGaugeController from "./rule_progress_gauge_controller"
import GaugeChartController from "./gauge_chart_controller"
import InfiniteScrollController from "./infinite_scroll_controller"
import MapController from "./map_controller"

Stimulus.register("autosubmit-onchange", AutosubmitOnchangeController)
Stimulus.register("rule-progress", RuleProgressController)
Stimulus.register("rule-progress-gauge", RuleProgressGaugeController)
Stimulus.register("gauge-chart", GaugeChartController)
Stimulus.register("infinite-scroll", InfiniteScrollController)
Stimulus.register("map", MapController)

window.Stimulus = Stimulus
