import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["nextButton", "spinner", "intersection", "list"]
  declare nextButtonTarget: HTMLAnchorElement
  declare spinnerTarget: HTMLElement
  declare intersectionTarget: HTMLElement
  declare listTarget: HTMLElement

  static values = {
    disabled: Boolean,
  }
  declare disabledValue: boolean

  observer?: IntersectionObserver
  observerTriggerCount: number = 0

  connect() {
    this.observerTriggerCount = 0
    const that = this
    // add IntersectionObserver
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            this.observerTriggerCount++
            that.handleClick()
            observer.unobserve(that.element)
          }
        })
      },
      { root: null, rootMargin: "0px", threshold: 0 }
    )
    if (!this.disabledValue) {
      observer.observe(this.intersectionTarget)
    }
    this.observer = observer
    this.hideSpinner()
  }
  disconnect() {
    this.observer?.disconnect()
  }

  handleClick(event?: Event) {
    if (event) {
      this.observerTriggerCount = 0
      event.preventDefault()
    }
    this.showSpinner()
    const url = this.nextButtonTarget.href
    this.requestNextHtml(url)
  }

  showSpinner = () => {
    if (this.spinnerTarget) {
      this.spinnerTarget.style.display = "block"
    }
  }

  hideSpinner = () => {
    if (this.spinnerTarget) {
      this.spinnerTarget.style.display = "none"
    }
  }
  requestNextHtml = (url: string) => {
    const that = this

    fetch(url)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          response.json().then((data) => {
            that.appendNewPosts(data.ratings)
            if (data.nextPageUrl) {
              that.nextButtonTarget.href = data.nextPageUrl
            } else {
              that.nextButtonTarget.style.display = "none"
              this.observer.disconnect()
            }
          })
        } else {
          console.error(response.status)
          this.hideSpinner()
        }
      })
      .catch(() => {
        that.hideSpinner()
      })
  }

  appendNewPosts(postsHTML: string) {
    this.listTarget.insertAdjacentHTML("beforeend", postsHTML)
    // console.log(this.listTarget.childElementCount)
    this.hideSpinner()
    if (this.observerTriggerCount < 3) {
      this.observer?.observe(this.intersectionTarget)
    } else {
      this.observer?.unobserve(this.intersectionTarget)
    }
  }
}
