import "tiny-slider/src/tiny-slider.scss"
import { tns } from "tiny-slider/src/tiny-slider.js"

const sliderCallback = () => {
  if (document.querySelector(".landingpage-slider")) {
    tns({
      container: ".landingpage-slider",
      nav: false,
      items: 1,
      gutter: 0,
      slideBy: "page",
      speed: 1000,
      swipAngle: false,
      controls: false,
      axis: "horizontal",
      swipeAngle: false,
      mouseDrag: true,
      autoplay: true,
      lazyload: true,
      autoplayButtonOutput: false,
      autoplayHoverPause: true,
      autoplayTimeout: 15000,
    })
  }

  if (document.querySelector(".rule-slider")) {
    tns({
      container: ".rule-slider",
      nav: false,
      items: 1,
      gutter: 0,
      slideBy: "page",
      speed: 1000,
      edgePadding: 20,
      loop: true,
      swipAngle: false,
      center: false,
      controls: false,
      responsive: {
        480: {
          items: 2,
        },
        768: {
          gutter: 0,
        },
        1024: {
          items: 4,
        },
      },
      axis: "horizontal",
      swipeAngle: false,
      mouseDrag: true,
      autoplay: false,
      autoplayButtonOutput: false,
      controlsContainer: "#customize-controls",
      autoplayTimeout: 5000,
    })
  }
}
document.addEventListener("turbolinks:load",  sliderCallback )
document.addEventListener("DOMContentLoaded", sliderCallback )
