import Vue from 'vue';
import VueI18n from 'vue-i18n';

import deContent from '../generated/locales/js.de'
import enContent from '../generated/locales/js.en'

Vue.use(VueI18n);
const i18n = new VueI18n({
  locale: window.Locale || 'de',
  messages: {
    de: deContent['de'],
    en: enContent['en']
  },
});

export default i18n;
