import { Controller } from "stimulus"

export default class extends Controller {
  eventListener: EventListener | null  = null

  connect() {
    this.eventListener = () => {
      const form = this.element.closest("form")
      if (form) form.submit()
    }
    this.element.addEventListener("change", this.eventListener)
  }
  disconnect() {
    if (this.eventListener) {
      this.element.removeEventListener("change", this.eventListener)
    }
  }
}
