
div
  small.text-muteD(v-if='helpfulCount == 1')
    | {{ $t('js.company.components.helpful.1_person_fand_diesen_komme') }}
  small.text-muted(v-else-if='helpfulCount > 0')
    |{{helpfulCount}} {{ $t('js.company.components.helpful.personen_fanden_diesen_kom') }}
  div
    button(class='btn btn-link pl-0' type='button' rel='nofollow' :title="$t('js.company.components.helpful.nicht_mehr_als_hilfreich_m')" v-if='helpful' @click.prevent='notHelpful')
      i(class='fas fa-thumbs-down fa-fw')
      |
      | {{ $t('js.company.components.helpful.nicht_hilfreich') }}
    button(class='btn btn-link pl-0' type='button' rel='nofollow' v-else @click.prevent='markHelpful')
      i(class='fa fa-thumbs-up ')
      | {{ $t('js.company.components.helpful.hilfreich') }}
