import Vue from 'vue'
Vue.config.productionTip = false
import $ from 'utils/jquery'
import axios from 'axios-jsonp-pro'

Vue.prototype.$http = axios;
Vue.prototype.axios = axios;
axios.defaults.baseURL = '/';
axios.defaults.withCredentials = false;
axios.defaults.headers.common['Accept'] = 'application/json'

$(document).ready(() => {
  const tokenDom = document.getElementsByName('csrf-token')[0]
  if (tokenDom) {
    const token = tokenDom.getAttribute('content')
    axios.defaults.headers.common['X-CSRF-Token'] = token
  }
})
