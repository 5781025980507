'use strict';

var windowIsTurbolinked = 'Turbolinks' in window;
function timer() {
  if (document.querySelector('#event-banner') == null) {
    return
  }
  var finaleDate = document.querySelector('#event-banner').dataset.start
  finaleDate = new Date(finaleDate).getTime();
  var now = new Date().getTime();
  var diff = finaleDate - now;
  // Showing the alert when the counter time finishes.
  if(diff < 0){
    document.querySelector('#event-banner').style.display = 'none';
  }

  var days = Math.floor(diff / (1000*60*60*24));
  var hours = Math.floor(diff % (1000*60*60*24) / (1000*60*60));
  var minutes = Math.floor(diff % (1000*60*60)/ (1000*60));
  var seconds = Math.floor(diff % (1000*60) / 1000);
  days <= 9 ? days = "0" + days : days;
  hours <= 9 ? hours = "0" + hours : hours;
  minutes <= 9 ? minutes = "0" + minutes : minutes;
  seconds <= 9 ? seconds = "0" + seconds : seconds;

  document.querySelector('#days > .bfg__time-entry-number').textContent = days;
  document.querySelector('#hours > .bfg__time-entry-number').textContent = hours;
  document.querySelector('#minutes > .bfg__time-entry-number').textContent = minutes;
  document.querySelector('#seconds > .bfg__time-entry-number').textContent = seconds;
}

(function() {
  function eventName(fallback) {
    return windowIsTurbolinked ? 'turbolinks:load' : fallback
  }

  var isCalled = false;

  function isReady() {
    if (isCalled && !windowIsTurbolinked) {
      return
    }
    isCalled = true;

    timer();
    setInterval(timer,1000);
  }

  if (document.addEventListener) {
    return document.addEventListener(eventName('DOMContentLoaded'), isReady, false);
  }

  if (window.addEventListener) {
    window.addEventListener(eventName('load'), isReady, false);
  } else if (window.attachEvent) {
    window.attachEvent(eventName('onload'), isReady);
  }
})();
