import $ from 'jquery'
import ClipboardJS from 'clipboard';

$(() => {
  const clipboard = new ClipboardJS('.clipboard-btn', {
    container: document.querySelectorAll('.modal.show')[0]
  });
  clipboard.on('success', (e) => {
    navigator.clipboard.writeText(e.text)
    const snack = $( "#copy-snackbar" )
    if ($(".snackbar.show").length > 0) {
      $(".snackbar.show").removeClass("show").one("webkitTransitionEnd transitionEnd", () => {
        snack.addClass( () => {
          setTimeout(() => {
              snack.removeClass("show")
            }, 6e3);
            return "show"
          })
        })
    } else {
      snack.addClass(() => {
        setTimeout(() => {
            snack.removeClass("show")
        }, 6e3);
        return "show"
      })
    }
    e.clearSelection();
  });

})

$(() => {
  new ClipboardJS('#invitelink');
})

