 function showSelectedFile() {
    var inputs = document.querySelectorAll( '.inputfile' );
    Array.prototype.forEach.call( inputs, function( input )
    {
        var labelVal = input.labels[0].innerHTML;

        input.addEventListener('change',function(e)
        {
            var fileName = '';
            fileName = e.target.value.split('\\').pop();
            if(fileName.length > 25)
            {
               var words = fileName.split(".");
               fileName = words[0].substring(0,9) + "..." + words[0].substring(words[0].length-5,words[0].length) + "." +words[1];
            }

            if(fileName)
                input.labels[0].innerHTML = fileName;
            else
                labelVal = labelVal;
        });
    });
}

export default showSelectedFile;