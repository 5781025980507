import ClipboardJS from 'clipboard';
import 'huebee/huebee.css'
import Huebee from 'huebee';
const clipboard = new ClipboardJS('.clipboard-btn');
clipboard.on('success', (e) => {
  const snack = $( "#copy-snackbar" )
  if ($(".snackbar.show").length > 0) {
    $(".snackbar.show").removeClass("show").one("webkitTransitionEnd transitionEnd", () => {
      snack.addClass( () => {
        setTimeout(() => {
            snack.removeClass("show")
          }, 6e3);
          return "show"
        })
      })
  } else {
    snack.addClass(() => {
      setTimeout(() => {
          snack.removeClass("show")
      }, 6e3);
      return "show"
    })
  }
  e.clearSelection();
});
var elems = document.querySelectorAll('.minicolors');
for ( var i=0; i < elems.length; i++ ) {
  var elem = elems[i];
  const hueb = new Huebee( elem, {
    setText: true,
    notation: 'hex'
  });
  hueb.on( 'change', function( color, hue, sat, lum ) {
    $("form.new_widget").change()
  })
}
$(() => {
  $("#widget_version").change((e) => {
    const widget_version = ($("#widget_version").val().replace(" ", "-"))
    if (widget_version === "Mit-Slideshow") {
      $("#widget_height").val('420');
      $("#widget_width").val('800');
    } else {
      $("#widget_height").val('650');
      $("#widget_width").val('350');
    }
  });
  $("form.new_widget").change((e) => {
    const user = e.currentTarget.dataset.user
    const widget_version = ($("#widget_version").val().replace(" ", "-"))
    const url = "//" + location.host + "/widget/" + user + "?version=" + widget_version + "&count=" + ($("#widget_count").val()) + "&score=" + ($("#widget_score").val()) + "&order=" + ($("#widget_order").val()) + "&badge=" + ($("#widget_badge").is(':checked')) + "&top=" + ($("#widget_top").is(':checked')) + "&border=" + ($("#widget_border").is(':checked')) + "&button=" + ($("#widget_button").is(':checked')) + "&location=" + ($("#widget_location").is(':checked')) + "&date=" + ($("#widget_date").is(':checked')) + "&color=" + ($("#widget_color").val().replace("#", "")) + "&bg_color=" + ($("#widget_bg_color").val().replace("#", "")) + "&text_color=" + ($("#widget_text_color").val().replace("#", "")) + "&link_color=" + ($("#widget_link_color").val().replace("#", "")) + "&button_bg_color=" + ($("#widget_button_bg_color").val().replace("#", "")) + "&button_border_color=" + ($("#widget_button_border_color").val().replace("#", "")) + "&button_text_color=" + ($("#widget_button_text_color").val().replace("#", "")) + "&type=" + ($("#widget_type").val().replace("/", "-")) + "&font=" + ($("input[name='widget[font]']:checked").val()) + "&quote=" + ($("input[type=checkbox][name*=quote]").is(':checked')) + "&now_button=" + ($("#widget_now_button").is(':checked'));

    $("#fk-widget").attr("src", url);

    $("#fk-widget").attr("height", $("#widget_height").val());
    $("#fk-widget").attr("width", $("#widget_width").val());


    $("#preview-code").text("<iframe src='" + url + "' height='" + ($("#widget_height").val()) + "' width='" + ($("#widget_width").val()) + "' frameBorder='0'></iframe>");

  }).change();
})
