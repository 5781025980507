import Turbolinks from "turbolinks"
import $ from "utils/jquery"
import turbolinksAddon from "utils/turbolinks-jquery"
import "bootstrap/js/dist/tab"
import "utils/axios-jsonp.js"
import "utils/Modal"
import "utils/rails"

turbolinksAddon($)

window.Turbolinks = Turbolinks
Turbolinks.start()

import "controllers"
import "application/javascript/framework"
import "application/style.scss"
import "application/javascript/star-rating"
import showSelectedFile from "application/javascript/input_file.js"
import "application/javascript/pagination.js"
import "application/javascript/lazysizes"
import "application/javascript/dashboard"
import "application/javascript/sliders"
import "widget_config"
import "company_page"
import "landing_page/index"
import Helpful from "company/components/Helpful.vue"

import AutomountAll from "utils/automountAll"
import i18n from "utils/i18n.js"

AutomountAll({
  "landingpage-search": {
    component: () => import("search/components/InputGroup.vue"),
    plugins: [{ i18n }]
  },
  "helpful": {
    component: Helpful,
    plugins: [{ i18n }]
  },
  "search-app": {
    component: () => import("job_search/App.vue"),
    plugins: [{ i18n }]
  },
  "import-sources-app": {
    component: () => import("company/components/ImportSourceForm.vue"),
    plugins: [{ i18n }]
  },
  "company-search-app": {
    component: () => import("job_search/App.vue"),
    plugins: [{ i18n }]
  },
  "radar-app": {
    component: () => import("company_page/App.vue"),
    plugins: [{ i18n }]
  },
  "combined-company-chart": {
    component: () => import("charts/CombinedCompanyChart.vue"),
  },
  "combined-company-chart-wrapper": {
    component: () => import("charts/CombinedCompanyChartWrapper.vue"),
  },
  "banner-image": {
    component: () => import("banner_image/index.vue"),
    plugins: [{ i18n }]
  },
  "spiderweb-chart": { component: () => import("charts/SpiderwebChart.vue") },
  "realization-spiderweb-chart": {
    component: () => import("charts/RealizationSpiderwebChart.vue"),
  },
  "heat-xhart": { component: () => import("charts/HeatChart.vue") },
  "pie-chart": { component: () => import("charts/PieChart.vue") },
  "clicks-month-chart": {
    component: () => import("charts/ClicksMonthChart.vue"),
  },
  "rating-chart": { component: () => import("charts/RatingChart.vue") },
  distributions: {
    component: () => import("charts/Distributions.vue"),
  },
  "company-settings": {
    component: () => import("company_settings/app.vue"),
    plugins: [{ i18n }]
  },
})

$(".hover-card").hover(
  () => {
    $(this).addClass("shadow-lg").css("cursor", "pointer")
  },
  () => {
    $(this).removeClass("shadow-lg")
  }
)

$(() => {
  showSelectedFile()
  $('[data-toggle="tooltip"]').tooltip()
})

$(document).on("shown.bs.tab", function () {
  window.dispatchEvent(new Event("resize"))
})

document.addEventListener("turbolinks:load", () => {
  $('#flash').modal('show')
})
document.addEventListener("DOMContentLoaded", () => {
  $('#flash').modal('show')
})
