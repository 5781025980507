export default {
  "en": {
    "js": {
      "banner_image": {
        "components": {
          "banners": {
            "banner_im_getbannertitle_b": "Banner in \"{getbannertitle_banner}\" layout",
            "banner_loschen": "Delete banner",
            "hinterlegte_banner": "Backed banners:",
            "hinzugefugt_am_banner_crea": "Added on {banner_created_at}",
            "neuen_banner_erstellen": "Create new banner",
            "verwendeter_banner": "Used banner:"
          }
        }
      },
      "company": {
        "components": {
          "helpful": {
            "1_person_fand_diesen_komme": "1 person found this comment helpful",
            "hilfreich": " helpful",
            "nicht_hilfreich": " not helpful",
            "nicht_mehr_als_hilfreich_m": "Do not mark as helpful anymore",
            "personen_fanden_diesen_kom": "People found this comment helpful"
          },
          "import_source_form": {
            "es_konnten_leider_keine_be": "Sorry, no reviews could be found under the given link.",
            "ladt": "...loads",
            "speichern": "Save >>",
            "url_testen": "Test URL",
            "waehlen_sie": "Choose a provider"
          }
        }
      },
      "company_page": {
        "components": {
          "rule_card": {
            "mehr_zur_regel": "More about the rule",
            "priority0": "is not working on this topic at the moment",
            "priority11": "is currently working on this topic",
            "priority12": "plans to work on this topic in the following 3 years",
            "priority34": "plans to work on this topic next year",
            "stand_der": "Status of implementation"
          }
        }
      },
      "company_settings": {
        "components": {
          "whitelist_domains": {
            "hinzufugen": "add",
            "ungultiger_domainname_bitt": "Invalid domain name. Please check your input."
          }
        }
      },
      "gauge_chart": {
        "stand_der": "Status of implementation"
      },
      "job_search": {
        "app": {
          "mehr_anzeigen": "Show more"
        },
        "components": {
          "job_item": {
            "ein_stellenangebot_auf": "a job offer on",
            "veroffentlicht_am_formated": "published on {formatedpubdate}",
            "zur_stellenanzeige": "Go to the job ad on {domain_name}"
          },
          "score_card": {
            "arbeitgeberbewertung": "Employer Assessment:",
            "ratingcount_bewertungen": "{ratingcount} ratings"
          },
          "search_input": {
            "all": "All",
            "art_der_position": "Position type",
            "erweiterte_suche": "advanced search",
            "jobtitel_oder_suchwort_z_b": "Job title or search word, e.g. developer or Java",
            "learner": "Apprentice/Internship",
            "ort": "Location",
            "ort_z_b_dresden": "Place, e.g. Dresden",
            "professional": "Specialists & Managers",
            "suchbegriff": "Search Keyword",
            "suche_im_umkreis": "Search around",
            "suchen": "Search"
          }
        }
      },
      "rule_chart": {
        "stand_der": "Status of implementation"
      },
      "search": {
        "input_group": {
          "placeholder": "Search for company",
          "search": "Search"
        }
      }
    }
  }
};
