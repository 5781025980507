<template lang="pug">
div
  small.text-muteD(v-if='helpfulCount == 1')
    | {{ $t('js.company.components.helpful.1_person_fand_diesen_komme') }}
  small.text-muted(v-else-if='helpfulCount > 0')
    |{{helpfulCount}} {{ $t('js.company.components.helpful.personen_fanden_diesen_kom') }}
  div
    button(class='btn btn-link pl-0' type='button' rel='nofollow' :title="$t('js.company.components.helpful.nicht_mehr_als_hilfreich_m')" v-if='helpful' @click.prevent='notHelpful')
      i(class='fas fa-thumbs-down fa-fw')
      |
      | {{ $t('js.company.components.helpful.nicht_hilfreich') }}
    button(class='btn btn-link pl-0' type='button' rel='nofollow' v-else @click.prevent='markHelpful')
      i(class='fa fa-thumbs-up ')
      | {{ $t('js.company.components.helpful.hilfreich') }}
</template>

<script lang="ts">
import Vue from "vue"

export default Vue.extend({
  props: {
    id: {
      type: Number,
      required: true,
    },
    state: {
      type: Boolean,
    },
    count: {
      type: Number,
    },
  },
  data() {
    return {
      helpful: this.state,
      helpfulCount: this.count,
    }
  },
  computed: {
    csrfToken() {
      return document.querySelector("meta[name=csrf-token]").getAttribute("content")
    },
  },
  methods: {
    markHelpful() {
      const that = this
      return fetch(`/ajax/helpful?id=${this.id}`, {
        method: "POST",
        headers: {
          "X-CSRF-Token": this.csrfToken
        },
      })
        .then(() => {
          that.helpful = true
          that.helpfulCount++
        })
        .catch((error) => {
          console.error(error)
        })
    },
    notHelpful() {
      const that = this
      return fetch(`/ajax/helpful?id=${this.id}`, {
        method: "DELETE",
        headers: {
          "X-CSRF-Token": this.csrfToken
        },
      })
        .then(() => {
          that.helpful = false
          that.helpfulCount--
        })
        .catch((error) => {
          console.error(error)
        })
    },
    setResponse(data: any) {
      this.helpful = data.found
        this.helpfulCount = data.helpful_count
    }
  },
})
</script>
<style scoped></style>
