import lazyframe from "lazyframe"
import $ from 'jquery'

$(()=>{
  lazyframe(".lazyframe")
  function onHashChange() {
    const hash = window.location.hash;
    if (hash) {
      $(`[data-toggle="tab"][href="${hash}"]`).trigger('click');
    }
  }
  window.addEventListener('hashchange', onHashChange, false);
  onHashChange();
})

