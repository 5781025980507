import { Controller } from "stimulus"

import Highcharts from "utils/highcharts"
import i18n from "utils/i18n.js"

const toPercent = (value: string) => {
  const split = value.split("/")
  const result = parseInt(split[0], 10) / parseInt(split[1], 10)
  return Math.round(result * 100)
}

export default class extends Controller {
  static values = {
    priority: String,
    progress: String,
    average: String,
  }
  declare progressValue: string
  declare averageValue: string
  declare priorityValue: string

  connect() {
    Highcharts.chart(this.element as HTMLDivElement, {
      chart: {
        type: "solidgauge",
        height: (9 / 16) * 100 + "%",
      },
      tooltip: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      pane: {
        center: ["50%", "80%"],
        size: "140%",
        startAngle: -90,
        endAngle: 90,
        background: {
          innerRadius: "70%",
          outerRadius: "100%",
          backgroundColor: "#ffffff",
          borderColor: "#a7a7a7",
          borderWidth: 1,
          shape: "arc",
        },
      },
      title: {
        text: i18n.t('js.gauge_chart.stand_der'),
        style: {
          fontSize: "1rem",
        },
      },
      yAxis: {
        title: {
          y: -100,
        },
        min: 0,
        max: 100,
        gridLineColor: "transparent",
        lineColor: "transparent",
        minorTickLength: 0,
        tickPositions: [toPercent(this.averageValue)],
        labels: {
          enabled: true,
          formatter: function () {
            return `<div class="bs-progress-benchmark-indicator" data-toggle="tooltip" data-placement="top" title="Durchschnitt aller Mitglieder ${this.value}%">&nbsp;</div>`
          },
          useHTML: true,
          x: 0,
          y: 23,
          style: {
            fontSize: "5rem",
            color: "white",
          },
        },
        tickColor: "#164a57",
        tickPosition: "inside",
        tickLength: 45,
        tickWidth: 8,
        zIndex: 100,
      },
      plotOptions: {
        solidgauge: {
          innerRadius: "70%",
          radius: "100%",
          dataLabels: {
            y: -17,
            borderWidth: 0,
          },
        },
      },

      series: [
        {
          name: i18n.t('js.gauge_chart.stand_der'),
          data: [
            {
              color: "#a2bf30",
              dataLabels: {
                format:
                  '<div style="text-align:center"><span style="font-size:2.3rem;color: #a2bf30">{y}%</span></div>',
              },
              y: toPercent(this.progressValue),
            },
          ],
        },
      ],
    })
  }
}
