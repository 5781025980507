import { Controller } from "stimulus"

import ProgressBar from "progressbar.js"

export default class extends Controller {
  static values = {
    percent: Number,
  }
  declare percentValue: number
  connect() {
    const bar = new ProgressBar.Line(this.element, {
      strokeWidth: 3,
      easing: "easeInOut",
      duration: 1400,
      text: {
        style: {
          // Text color.
          // Default: same as stroke color (options.color)
          color: "#999",
          position: "absolute",
          right: "0",
          top: "30px",
          padding: 0,
          margin: 0,
          transform: null,
        },
      },
      color: "#164a57",
      trailColor: "#eee",
      trailWidth: 1,
      svgStyle: { width: "100%", height: "100%" },
      from: { color: "#FFEA82" },
      to: { color: "#ED6A5A" },
      step: (state, bar) => {
        bar.setText(Math.round(bar.value() * 100) + " %")
      },
    })
    bar.animate(this.percentValue)
  }
}
