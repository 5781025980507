import $ from "utils/jquery"
import "landing_page/countdown.js"

const btn = $("#to-top-btn")

$(window).scroll(function () {
  if ($(window).scrollTop() > 300) {
    btn.removeClass("d-none")
  } else {
    btn.addClass("d-none")
  }
})

btn.on("click", function (e) {
  e.preventDefault()
  $("html, body").animate({ scrollTop: 0 }, "400")
})

$(() => {
  $(window).scroll(function () {
    if ($(this).scrollTop() > 500) $("#fk-signup").fadeIn("slow")
    else $("#fk-signup").hide("easing")
  })
  $("#more-efqm").click(function () {
    $([document.documentElement, document.body]).animate(
      {
        scrollTop: $(".rules").offset().top,
      },
      300
    )
  })
})
