import $ from 'jquery'
import 'bootstrap-star-rating';
import 'bootstrap-star-rating/themes/krajee-fas/theme.js'


const captions = {
  1: 'triff nicht zu',
  2: 'trifft eher nicht zu',
  3: 'trifft teilweise zu',
  4: 'trifft eher zu',
  5: 'trifft voll zu'
}

$(document).ready(() => {
  $(".stars").rating({theme: 'krajee-fa', containerClass: 'is-star', step: 1, emptyStar: "<i class='far fa-star fa-fw'></i>", filledStar: "<i class='fas fa-star fa-fw'></i>", starCaptions: captions, hoverChangeCaption: false, showCaptionAsTitle: true, clearButton: "<i class='fas fa-minus-circle fa-fw'></i>", clearCaption: 'noch keine Bewertung'});
})
