import { Controller } from "stimulus"

import ProgressBar from "progressbar.js"

export default class extends Controller {
  static values = {
    percent: Number,
    color: String,
  }
  declare colorValue: string
  declare percentValue: number

  connect() {
    const bar = new ProgressBar.Line(this.element, {
      strokeWidth: 5,
      easing: "easeInOut",
      duration: 1400,
      color: this.colorValue,
      trailColor: "#eee",
      trailWidth: 1,
      svgStyle: { width: "100%", height: "100%" },
      from: { color: "#FFEA82" },
      to: { color: "#ED6A5A" },
    })
    bar.animate(this.percentValue)
  }
}
