export default {
  "de": {
    "js": {
      "banner_image": {
        "components": {
          "banners": {
            "banner_im_getbannertitle_b": "Banner im „{getbannertitle_banner}“-Layout",
            "banner_loschen": "Banner löschen",
            "hinterlegte_banner": "Hinterlegte Banner:",
            "hinzugefugt_am_banner_crea": "Hinzugefügt am {banner_created_at}",
            "neuen_banner_erstellen": "Neuen Banner erstellen",
            "verwendeter_banner": "Verwendeter Banner:"
          }
        }
      },
      "company": {
        "components": {
          "helpful": {
            "1_person_fand_diesen_komme": "1 Person fand diesen Kommentar hilfreich",
            "hilfreich": " hilfreich",
            "nicht_hilfreich": " nicht hilfreich",
            "nicht_mehr_als_hilfreich_m": "Nicht mehr als hilfreich markieren",
            "personen_fanden_diesen_kom": "Personen fanden diesen Kommentar hilfreich"
          },
          "import_source_form": {
            "es_konnten_leider_keine_be": "Es konnten leider keine Bewertungen unter dem angegebenen Link gefunden werden.",
            "ladt": "...lädt",
            "speichern": "Speichern >>",
            "url_testen": "URL testen",
            "waehlen_sie": "Wählen Sie einen Anbieter"
          }
        }
      },
      "company_page": {
        "components": {
          "rule_card": {
            "mehr_zur_regel": "Mehr zur Regel",
            "priority0": "bearbeitet dieses Thema momentan nicht",
            "priority11": "arbeitet gerade an diesem Thema",
            "priority12": "plant die Bearbeitung dieses Themas in den folgenden 3 Jahren",
            "priority34": "plant die Bearbeitung dieses Themas im nächsten Jahr",
            "stand_der": "Stand der Umsetzung"
          }
        }
      },
      "company_settings": {
        "components": {
          "whitelist_domains": {
            "hinzufugen": "hinzufügen",
            "ungultiger_domainname_bitt": "Ungültiger Domainname. Bitte überprüfen Sie ihre Eingabe."
          }
        }
      },
      "gauge_chart": {
        "stand_der": "Stand der Umsetzung"
      },
      "job_search": {
        "app": {
          "mehr_anzeigen": "Mehr anzeigen"
        },
        "components": {
          "job_item": {
            "ein_stellenangebot_auf": "ein Stellenangebot auf",
            "veroffentlicht_am_formated": "veröffentlicht am {formatedpubdate}",
            "zur_stellenanzeige": "Zur Stellenanzeige auf {domain_name}"
          },
          "score_card": {
            "arbeitgeberbewertung": "Arbeitgeberbewertung:",
            "ratingcount_bewertungen": "{ratingcount} Bewertungen"
          },
          "search_input": {
            "all": "Alle",
            "art_der_position": "Art der Position",
            "erweiterte_suche": "erweiterte Suche",
            "jobtitel_oder_suchwort_z_b": "Jobtitel oder Suchwort, z.B. Entwickler oder Java",
            "learner": "Lernende/Praktikum",
            "ort": "Ort",
            "ort_z_b_dresden": "Ort, z.B. Dresden",
            "professional": "Fach- u. Führungskräfte",
            "suchbegriff": "Suchbegriff",
            "suche_im_umkreis": "Suche im Umkreis",
            "suchen": "Suchen"
          }
        }
      },
      "rule_chart": {
        "stand_der": "Stand der Umsetzung"
      },
      "search": {
        "input_group": {
          "placeholder": "Suche nach Unternehmen",
          "search": "Suchen"
        }
      }
    }
  }
};
