// Import JS from Leaflet and plugins.
import L from "leaflet"
import $ from 'jquery'

import "leaflet/dist/leaflet.css"

// stupid hack so that leaflet's images work after going through webpack
import marker from "leaflet/dist/images/marker-icon.png"
import marker2x from "leaflet/dist/images/marker-icon-2x.png"
import markerShadow from "leaflet/dist/images/marker-shadow.png"

delete L.Icon.Default.prototype._getIconUrl

L.Icon.Default.mergeOptions({
  iconRetinaUrl: marker2x,
  iconUrl: marker,
  shadowUrl: markerShadow,
})

import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    coords: Array,
    title: String,
  }
  declare coordsValue: [number, number]
  declare titleValue: string
  static targets = []

  connect() {
    // osmUrl = 'http://otile1.mqcdn.com/tiles/1.0.0/osm/{z}/{x}/{y}.png'
    // osmUrl = 'http://{s}.tile.osm.org/{z}/{x}/{y}.png'
    const osmUrl = "/system/tiles/{z}/{x}/{y}.png"
    const osm = new L.TileLayer(osmUrl, {
      attribution: "Map data © OpenStreetMap contributors",
    })
    const map = L.map(this.element, {
      zoomControl: false,
    })
    map.scrollWheelZoom.disable()
    // map.dragging.disable()
    map.touchZoom.disable()
    map.doubleClickZoom.disable()
    map.boxZoom.disable()
    map.keyboard.disable()
    if (map.tap) {
      map.tap.disable()
    }
    map.addLayer(osm)
    map.setView(this.coordsValue, 11)
    L.marker(this.coordsValue).addTo(map).bindPopup(this.titleValue)
    map.resetAfterTab = () => {
      map.invalidateSize()
      map.setView(this.coordsValue, 11)
    }
    setTimeout(function () {
      return map.resetAfterTab()
    }, 500)

    $(document).on("show", ".tab-pane", function () {
      map.resetAfterTab()
    })
  }
}
